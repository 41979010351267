import { filtersFormController } from 'magner';
import { baseT } from 'configs/translation/base';
import { searchCities, searchBreed } from '~/utils/searchInit';
import type { Filters } from './types';
import { audienceList, statusesFilters, objectList } from '~/utils/list';

const discountsFilters = filtersFormController<Filters>({
  fieldsShowAmount: 5,
  actions: [
    {
      type: 'link',
      to: { name: 'discounts-edit', params: { id: 'new' } },
      props: {
        type: 'primary',
        class: 'blue-btn',
        text: baseT('form.create_new_button'),
      },
    },
  ],
  submitEvent: 'input',
  sort: {},
  pagination: {
    items: 25,
    page: 1,
  },
  filtersData: {
    city: '',
    promo: '',
    object: '',
    audience: '',
    breed: '',
    phone: '',
    active: '',
  },
  layout: [
    {
      type: 'select',
      name: 'city',
      options: [],
      props: {
        placeholder: baseT('label.city'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: searchCities,
      },
    },
    {
      type: 'input',
      name: 'promo',
      props: {
        placeholder: 'Промокод',
      },
    },
    {
      type: 'select',
      name: 'audience',
      options: audienceList,
      props: {
        placeholder: 'Тип аудитории',
        multiple: true,
        collapseTags: true,
        clearable: true,
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      type: 'select',
      name: 'object',
      options: objectList,
      props: {
        placeholder: 'Объект скидки',
        multiple: true,
        collapseTags: true,
        clearable: true,
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      type: 'select',
      name: 'breed',
      options: [],
      props: {
        placeholder: 'Породы питомцев',
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'title',
        remoteMethod: searchBreed,
      },
    },
    {
      type: 'input',
      name: 'phone',
      props: {
        placeholder: 'Конкретный клиент',
        mask: {
          mask: '+7#########',
        },
      },
    },
    {
      type: 'select',
      name: 'active',
      options: statusesFilters,
      props: {
        placeholder: 'Статус скидки',
        collapseTags: true,
        clearable: true,
        valueKey: 'value',
        labelKey: 'label',
      },
    },
  ],
});

export default discountsFilters;
