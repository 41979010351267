import { ft } from '~/utils/feature-translate';

export const baseRu = {
  sidebar: {
    order_group: 'Заказы',
    users_group: 'Пользователи',
    catalog_group: 'Каталог',
    settings_group: 'Настройки',
    content_group: 'Контент',
    ads_push: 'Рассылки',
  },
  form: {
    submit_button: 'Сохранить',
    create_button: 'Создать',
    cancel_button: 'Отменить',
    cancel_order_button: 'Отменить заказ',
    remove_button: 'Удалить',
    create_new_button: 'Добавить',
    start_button: 'Начать работу',
    edit_button: 'Редактировать',
    no_edit_button: 'Закончить без изменений',
    back_button: 'Назад',
    close_button: 'Закрыть',
    unpin_button: 'Открепить',
    reject: 'Отклонить',
    approve: 'Одобрить',
    execute: 'Выполнить',
  },
  placeholder: {
    text: 'Введите текст',
    number: 'Введите число',
    price: '... ₽',
    city: 'Введите город',
    select: 'Выберите',
    userName: 'Введите имя',
    surname: 'Введите фамилию',
    role: 'Выберите роль',
    status: 'Выберите статус',
    phone: '+7...',
    email: 'Введите e-mail',
    category: 'Выберите категорию',
  },
  label: {
    name: 'Название',
    city: 'Город',
    description: 'Описание',
    status: 'Статус',
    userName: 'Имя',
    surname: 'Фамилия',
    role: 'Роль',
    phone: 'Телефон',
    email: 'E-mail',
    category: 'Категория',
    period: 'Период',
  },
  status: {
    active: 'Активный',
    inactive: 'Неактивный',
    blocked: 'Заблокирован',
    request: 'Запрос на удаление',
    removed: 'Удален',
  },
  roles: {
    admin: 'Администратор',
    user: 'Пользователь',
    manager: 'Менеджер',
  },
  alert: {
    fail: 'Не удалось сделать запрос',
  },
};

export const baseT = ft(baseRu, 'base.');
