import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import OrderStatusSelectForCard from 'features/orders/components/order-status-select-for-card.vue';
import { openCancelWalkingModal } from 'features/orders/utils';
import {
  cardSearchDistricts,
  notificationTypes,
  paymentTypes,
  searchCities,
  searchSourceForCard,
  suggustedStreets,
} from '~/utils/searchInit';
import getDatetimeDefaultTimezone from '~/utils/get-datetime-default-timezone';
import { init } from '~/utils/get-init';
import { t } from './ru';
import type { OrderFrontend } from './types';
import {
  get,
  create,
  update,
  unpinOrder,
} from './requests';
import PetsSelector from './components/pets-selector.vue';
import UserSelector from './components/user-selector.vue';
import WalkerSelector from './components/walker-selector.vue';
import WalkerManualTable from './components/walker-manual-table.vue';
import WalkServices from './components/walk-services.vue';
import AddressSelector from './components/address-selector.vue';
import ProcessingManager from './components/processing-manager.vue';
import { WalkingStatuses } from '~/types/common';
import { roundToTwoSigns } from '~/utils/date-transform';

const MAX_DEFAULT_PETS = 2;

export const cardConfig = cardPageController<OrderFrontend>({
  getRequest: get,
  createRequest: create,
  updateRequest: update,

  header: {
    title: 'Заказ',
  },

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'orders' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        props: {
          type: 'primary',
          plain: true,
          text: baseT('form.no_edit_button'),
          hidden: ({ state }) => Object.keys(state.user).length === 0
            || state.status === WalkingStatuses.completed
            || state.status === WalkingStatuses.canceled,
        },
        action: unpinOrder,
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          hidden: ({ state }) => state.status === WalkingStatuses.completed
            || state.status === WalkingStatuses.canceled,
        },
      },
      {
        type: 'action',
        props: {
          type: 'danger',
          text: baseT('form.cancel_order_button'),
          hidden: ({ state, isNew }) => isNew
            || state.status === WalkingStatuses.completed
            || state.status === WalkingStatuses.canceled
            || state.status === WalkingStatuses.walking,
        },
        action: openCancelWalkingModal,
      },
    ],

    layout: {
      type: 'column',
      props: {},
      layout: [
        {
          type: 'row',
          props: {},
          layout: [
            {
              type: 'column',
              props: {
                span: 24,
                xs: 24,
                styles: { overflow: 'hidden', height: '0' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'isDistrictStreetDirty',
                  dataType: 'number',
                  props: {},
                  validation: [
                    {
                      type: 'set-dirty-form' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
              ],
            },

            /** TOP ROW with upper buttons */
            {
              type: 'column',
              props: {
                span: 24,
                xs: 24,
              },
              fields: [
                {
                  type: 'custom',
                  name: 'processingManager',
                  component: () => ProcessingManager,
                  props: {},
                },
                {
                  type: 'custom',
                  name: 'status',
                  component: () => OrderStatusSelectForCard,
                  props: {
                    hidden: (val) => val.isNew,
                    disabled: ({ state }) => state.status === WalkingStatuses.completed
                      || state.status === WalkingStatuses.canceled,
                  },
                },
              ],
            },

            /** LEFT COLUMN */
            {
              type: 'column',
              props: {
                span: 12,
                xs: 24,
                styles: { 'padding-right': '12px' },
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  layout: [
                    /** WalkDate, Walktime, Asap */
                    {
                      type: 'row',
                      props: {
                        styles: { gap: '0px 12px', 'flex-wrap': 'nowrap' },
                        elementsGrow: true,
                      },
                      fields: [
                        {
                          type: 'datetime',
                          name: 'startTime',
                          label: t('form.fields.walkDate'),
                          dataType: 'date',
                          changeAction: ({ form }) => {
                            form.time = null;
                          },
                          props: {
                            required: true,
                            type: 'date',
                            format: 'DD.MM.YYYY',
                            valueFormat: 'YYYY-MM-DD',
                            placeholder: t('form.fields.walkDate'),
                            disabledDate: (time: Date) => {
                              const dateCityNow = getDatetimeDefaultTimezone();
                              const dayMilliseconds = 24 * 60 * 60 * 1000;

                              dateCityNow.setMinutes(
                                dateCityNow.getMinutes() + 180,
                              );

                              return (
                                time.getTime()
                                < dateCityNow.getTime() - dayMilliseconds
                              );
                            },
                            disabled: ({ state }) => !state.city
                              || state.asap === 1
                              || state.status === WalkingStatuses.walking
                              || state.status === WalkingStatuses.completed
                              || state.status === WalkingStatuses.canceled,
                          },

                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'datetime',
                          name: 'time',
                          label: t('form.fields.walkTime'),
                          dataType: 'date',
                          changeAction: ({ form }) => {
                            const dateCityNow = getDatetimeDefaultTimezone();
                            const activeCity = init
                              .get()
                              .cities.find((cityItem) => cityItem.id === form.city);

                            if (activeCity?.minutesOffset) {
                              dateCityNow.setMinutes(
                                dateCityNow.getMinutes() + activeCity.minutesOffset,
                              );
                            }

                            /**
                             * Если дата сегодня, то:
                             * 1) если заказ срочный - не допускать выбора значения в прошедшем времени
                             * 2) если заказ НЕ срочный - не допускать выбора значения ранее чем через час
                             */
                            if (
                              `${dateCityNow.getFullYear()}-${roundToTwoSigns(
                                dateCityNow.getMonth() + 1,
                              )}-${roundToTwoSigns(dateCityNow.getDate())}`
                              === form.startTime
                            ) {
                              // if (form.asap === 1) {
                              //   dateCityNow.setMinutes(dateCityNow.getMinutes() + 30);
                              // }
                              // else {
                              //   dateCityNow.setHours(dateCityNow.getHours() + 1);
                              // }

                              if (
                                new Date(form.time).getTime()
                                < dateCityNow.getTime()
                              ) {
                                form.time = dateCityNow;
                              }
                            }
                          },
                          props: {
                            required: true,
                            type: 'time',
                            format: 'HH:mm',
                            valueFormat: 'HH:mm',
                            placeholder: t('form.fields.walkTime'),
                            disabled: ({ state }) => !state.startTime
                              || state.status === WalkingStatuses.walking
                              || state.status === WalkingStatuses.completed
                              || state.status === WalkingStatuses.canceled,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'select',
                          name: 'asap',
                          options: [
                            {
                              label: 'Да',
                              value: 1,
                            },
                            {
                              label: 'Нет',
                              value: 2,
                            },
                          ],
                          label: t('form.fields.urgent'),
                          dataType: 'string',
                          changeAction: ({ form }) => {
                            if (form.asap === 1) {
                              const dateCityNow = getDatetimeDefaultTimezone();
                              const activeCity = init
                                .get()
                                .cities.find(
                                  (cityItem) => cityItem.id === form.city,
                                );

                              if (activeCity?.minutesOffset) {
                                dateCityNow.setMinutes(
                                  dateCityNow.getMinutes()
                                    + activeCity.minutesOffset,
                                );
                              }

                              form.startTime = `${dateCityNow.getFullYear()}-${roundToTwoSigns(
                                dateCityNow.getMonth() + 1,
                              )}-${roundToTwoSigns(dateCityNow.getDate())}`;
                              dateCityNow.setMinutes(dateCityNow.getMinutes() + 30);
                              form.time = dateCityNow;
                            }
                          },
                          props: {
                            disabled: ({ state }) => !state.city
                              || state.status === WalkingStatuses.walking
                              || state.status === WalkingStatuses.completed
                              || state.status === WalkingStatuses.canceled,
                          },
                        },
                      ],
                    },
                    /** City, Communication, Payment Method */
                    {
                      type: 'row',
                      props: {
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'select',
                          name: 'city',
                          options: [],
                          label: t('form.fields.city'),
                          dataType: 'string',
                          changeAction: ({ form }) => {
                            // При смене города сбрасываем район
                            form.district = null;
                            form.isDistrictStreetDirty = 2;
                          },
                          props: {
                            required: true,
                            valueKey: 'id',
                            labelKey: 'name',
                            placeholder: t('form.fields.city'),
                            filterable: true,
                            remote: true,
                            remoteMethod: searchCities,
                            disabled: ({ state, isNew }) => !isNew
                              && state.status !== WalkingStatuses.waitingPayment,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'select',
                          name: 'notificationType',
                          options: [],
                          label: t('form.fields.communication.label'),
                          dataType: 'string',
                          props: {
                            required: true,
                            placeholder: t('form.fields.communication.placeholder'),
                            remote: true,
                            remoteMethod: notificationTypes,
                            disabled: ({ state }) => state.status === WalkingStatuses.walking
                              || state.status === WalkingStatuses.completed
                              || state.status === WalkingStatuses.canceled,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'select',
                          name: 'paymentType',
                          options: [],
                          label: t('form.fields.payment.label'),
                          dataType: 'string',
                          props: {
                            required: true,
                            placeholder: t('form.fields.payment.placeholder'),
                            remote: true,
                            remoteMethod: paymentTypes,
                            disabled: ({ state, isNew }) => !isNew
                              && state.status !== WalkingStatuses.waitingPayment,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                    /** externalId, Source, Payment Status, Manual payment */
                    {
                      type: 'row',
                      props: {
                        styles: { gap: '0px 12px', 'flex-wrap': 'nowrap' },
                        elementsGrow: true,
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'externalId',
                          label: t('form.fields.externalId'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.fields.externalId'),
                            disabled: ({ state }) => state.status === WalkingStatuses.walking
                              || state.status === WalkingStatuses.completed
                              || state.status === WalkingStatuses.canceled,
                          },
                        },
                        {
                          type: 'select',
                          name: 'source',
                          options: [],
                          label: t('form.fields.source.label'),
                          dataType: 'string',
                          props: {
                            required: true,
                            placeholder: t('form.fields.source.placeholder'),
                            valueKey: 'value',
                            labelKey: 'label',
                            disabled: ({ isNew }) => !isNew,
                            remote: true,
                            remoteMethod: searchSourceForCard,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },

                        {
                          type: 'select',
                          name: 'isPaid',
                          options: [
                            {
                              label: 'Не оплачено',
                              value: false,
                            },
                            {
                              label: 'Оплачено',
                              value: true,
                            },
                          ],
                          dataType: 'boolean',
                          label: t('form.fields.paymentState'),
                          props: {
                            class: 'is-paid-select order-edit-card-is-paid',
                            placeholder: t('form.fields.paymentState'),
                            readOnly: true,
                            readOnlyFormatter: (val) => (val ? 'Оплачено' : 'Не оплачено'),
                          },
                        },
                      ],
                    },
                    /** SERVICES */
                    {
                      type: 'column',
                      props: {
                        span: 24,
                        xs: 24,
                        isPaper: true,
                        titleType: 'heading',
                      },
                      fields: [
                        {
                          type: 'custom',
                          name: 'walkingType',
                          dataType: 'object',
                          component: () => WalkServices,
                          props: {
                            disabled: ({ state, isNew }) => {
                              if (!state.city) {
                                return true;
                              }

                              return (
                                (!isNew
                                  && state.status
                                    !== WalkingStatuses.waitingPayment)
                                || !state?.walkingType.offer
                              );
                            },
                          },
                          changeAction: ({ form }) => {
                            // Если в услугах есть "Дополнительно", то количество питомцев может быть только 1
                            if (
                              form.walkingType?.addition?.length
                            ) {
                              form.petsEdit = form.petsEdit.slice(0, 1);
                            }
                          },
                          validation: [
                            {
                              type: 'walk-services' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                      ],
                    },

                    /** PETS */
                    {
                      type: 'column',
                      title: t('form.pets.title'),
                      props: {
                        span: 24,
                        isPaper: true,
                        titleType: 'heading',
                      },
                      fields: [
                        {
                          type: 'collection',
                          name: 'petsEdit',
                          dataType: 'array',
                          props: {
                            firstRemovable: false,
                            createFirstItemIfNew: true,
                            disabled: ({ state, isNew }) => !isNew
                              && state.status !== WalkingStatuses.waitingPayment,
                            hiddenCollectionAddButton: ({ state }) => (state.walkingType?.addition?.length
                              ? state.petsEdit.length >= 1
                              : state.petsEdit.length >= MAX_DEFAULT_PETS),
                          },
                          layout: [
                            {
                              type: 'row',
                              props: { elementsGrow: true },
                              fields: [
                                {
                                  type: 'custom',
                                  name: 'pet',
                                  component: () => PetsSelector,
                                  props: {},
                                  validation: [
                                    {
                                      type: 'empty-required-pet' as 'empty',
                                      trigger: 'input',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },

                    /** PAYMENTS */
                    {
                      type: 'column',
                      title: t('form.payments.title'),
                      props: {
                        span: 24,
                        isPaper: true,
                        titleType: 'heading',
                        hidden: ({ state, isNew }) => !state.payments.length || isNew,
                      },
                      fields: [
                        {
                          type: 'collection',
                          name: 'payments',
                          dataType: 'array',
                          props: {
                            readOnly: true,
                          },
                          layout: [
                            /** Number, Amount, Status */
                            {
                              type: 'row',
                              props: {
                                align: 'bottom',
                                elementsGrow: true,
                                styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                              },
                              fields: [
                                {
                                  type: 'input',
                                  name: 'id',
                                  label: t('form.payments.fields.number'),
                                  dataType: 'string',
                                  props: {
                                    placeholder: t('form.payments.fields.number'),
                                    disabled: true,
                                  },
                                },
                                {
                                  type: 'input',
                                  name: 'amount',
                                  label: t('form.payments.fields.amount'),
                                  dataType: 'string',
                                  props: {
                                    placeholder: t('form.payments.fields.amount'),
                                    disabled: true,
                                  },
                                },
                                {
                                  type: 'input',
                                  name: 'status',
                                  label: t('form.payments.fields.status'),
                                  dataType: 'string',
                                  props: {
                                    placeholder: t('form.payments.fields.status'),
                                    disabled: true,
                                  },
                                },
                              ],
                            },
                            /** createdAt, expireAt */
                            {
                              type: 'row',
                              props: {
                                align: 'bottom',
                                elementsGrow: true,
                                styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                              },
                              fields: [
                                {
                                  type: 'datetime',
                                  name: 'createdAt',
                                  label: t('form.payments.fields.createdAt'),
                                  dataType: 'date',
                                  props: {
                                    type: 'date',
                                    format: 'DD.MM.YYYY HH:mm',
                                    placeholder: t(
                                      'form.payments.fields.createdAt',
                                    ),
                                    disabled: true,
                                  },
                                },
                                {
                                  type: 'datetime',
                                  name: 'expireAt',
                                  label: t('form.payments.fields.expireAt'),
                                  dataType: 'date',
                                  props: {
                                    type: 'date',
                                    format: 'DD.MM.YYYY HH:mm',
                                    placeholder: t('form.payments.fields.expireAt'),
                                    disabled: true,
                                  },
                                },
                              ],
                            },
                            /** Payment Link */
                            {
                              type: 'row',
                              props: {
                                align: 'bottom',
                                elementsGrow: true,
                                styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                              },
                              fields: [
                                {
                                  type: 'input',
                                  name: 'formUrl',
                                  label: t('form.payments.fields.paymentLink'),
                                  dataType: 'string',
                                  props: {
                                    placeholder: t(
                                      'form.payments.fields.paymentLink',
                                    ),
                                    disabled: true,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            /** RIGHT COLUMN */
            {
              type: 'column',
              props: {
                span: 12,
                xs: 24,
                styles: { 'padding-left': '12px' },
              },
              layout: [
                /** CLIENT */
                {
                  type: 'column',
                  title: t('form.client.title'),
                  props: {
                    span: 24,
                    isPaper: true,
                    titleType: 'heading',
                  },
                  layout: [
                    {
                      type: 'row',
                      props: {},
                      fields: [
                        {
                          type: 'custom',
                          name: 'user',
                          props: {
                            type: 'client',
                            readOnly: ({ state, isNew }) => !isNew
                              || state.status === WalkingStatuses.walking
                              || state.status === WalkingStatuses.completed
                              || state.status === WalkingStatuses.canceled,
                          },
                          component: () => UserSelector,
                          validation: [
                            {
                              type: 'empty-required-user' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },

                /** WALKER */
                {
                  type: 'column',
                  props: {
                    span: 24,
                  },
                  layout: [
                    {
                      type: 'row',
                      props: {},
                      fields: [
                        {
                          type: 'custom',
                          name: 'walker',
                          component: () => WalkerSelector,
                          props: {
                            type: 'walker',
                            readOnly: ({ state }) => state.status === WalkingStatuses.walking
                              || state.status === WalkingStatuses.completed
                              || state.status === WalkingStatuses.canceled,
                          },
                        },
                      ],
                    },
                  ],
                },

                /** ADDRESS */
                {
                  type: 'column',
                  title: t('form.address.title'),
                  props: {
                    span: 24,
                    isPaper: true,
                    titleType: 'heading',
                  },
                  layout: [
                    /** Existing address */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'custom',
                          name: 'addressExisting',
                          dataType: 'object',
                          component: () => AddressSelector,
                          props: {
                            readOnly: ({ state }) => state.status === WalkingStatuses.walking
                              || state.status === WalkingStatuses.completed
                              || state.status === WalkingStatuses.canceled,
                          },
                        },
                      ],
                    },
                    /** City, District */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'select',
                          name: 'city',
                          options: [],
                          label: t('form.fields.city'),
                          dataType: 'string',
                          props: {
                            class: 'w-100',
                            valueKey: 'id',
                            labelKey: 'name',
                            filterable: true,
                            remote: true,
                            remoteMethod: searchCities,
                            hidden: ({ state }) => state.addressExisting?.type === 'existing'
                              && !state.addressExisting?.existingId,
                            disabled: true,
                          },
                          changeAction: ({ form }) => {
                            form.isDistrictStreetDirty = 1;
                            form.street = '';
                          },
                        },
                        {
                          type: 'select',
                          name: 'district',
                          dataType: 'string',
                          options: [],
                          label: t('form.address.fields.district'),
                          props: {
                            class: 'w-100',
                            valueKey: 'id',
                            labelKey: 'slug',
                            required: true,
                            remote: true,
                            filterable: true,
                            remoteMethod: cardSearchDistricts,
                            loadRemoteMethodOnFocus: true,
                            hidden: ({ state }) => state.addressExisting?.type === 'existing'
                              && !state.addressExisting?.existingId,
                            disabled: ({ state }) => !state.city
                              || state.addressExisting?.type === 'existing',
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                          changeAction: ({ form }) => {
                            form.street = '';
                          },
                        },
                      ],
                    },
                    /** Street, House */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'select',
                          name: 'street',
                          dataType: 'string',
                          options: [],
                          label: t('form.address.fields.street'),
                          props: {
                            class: 'w-100',
                            required: true,
                            remote: true,
                            filterable: true,
                            remoteMethod: suggustedStreets,
                            loadRemoteMethodOnFocus: true,
                            hidden: ({ state }) => state.addressExisting?.type === 'existing'
                              && !state.addressExisting?.existingId,
                            disabled: ({ state }) => !state.city
                              || state.addressExisting?.type === 'existing'
                              || !state.district,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'house',
                          label: t('form.address.fields.house'),
                          dataType: 'string',
                          props: {
                            required: true,
                            hidden: ({ state }) => state.addressExisting?.type === 'existing'
                              && !state.addressExisting?.existingId,
                            disabled: ({ state }) => !state.city
                              || state.addressExisting?.type === 'existing',
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                    /** Entrance, Floor, Flat, DoorCode */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'entrance',
                          label: t('form.address.fields.porch'),
                          dataType: 'string',
                          props: {
                            hidden: ({ state }) => state.addressExisting?.type === 'existing'
                              && !state.addressExisting?.existingId,
                            disabled: ({ state }) => !state.city
                              || state.addressExisting?.type === 'existing',
                          },
                        },
                        {
                          type: 'input',
                          name: 'floor',
                          label: t('form.address.fields.floor'),
                          dataType: 'string',
                          props: {
                            hidden: ({ state }) => state.addressExisting?.type === 'existing'
                              && !state.addressExisting?.existingId,
                            disabled: ({ state }) => !state.city
                              || state.addressExisting?.type === 'existing',
                          },
                        },
                        {
                          type: 'input',
                          name: 'office',
                          label: t('form.address.fields.flat'),
                          dataType: 'string',
                          props: {
                            hidden: ({ state }) => state.addressExisting?.type === 'existing'
                              && !state.addressExisting?.existingId,
                            disabled: ({ state }) => !state.city
                              || state.addressExisting?.type === 'existing',
                          },
                        },
                        {
                          type: 'input',
                          name: 'doorCode',
                          label: t('form.address.fields.intercom'),
                          dataType: 'string',
                          props: {
                            hidden: ({ state }) => state.addressExisting?.type === 'existing'
                              && !state.addressExisting?.existingId,
                            disabled: ({ state }) => !state.city
                              || state.addressExisting?.type === 'existing',
                          },
                        },
                      ],
                    },
                    /** Comment */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'addressComment',
                          label: t('form.address.fields.comment'),
                          dataType: 'string',
                          props: {
                            hidden: ({ state }) => state.addressExisting?.type === 'existing'
                              && !state.addressExisting?.existingId,
                            disabled: ({ state }) => !state.city
                              || state.addressExisting?.type === 'existing',
                          },
                        },
                      ],
                    },
                  ],
                },

                /** CONTACT */
                {
                  type: 'column',
                  title: t('form.contact.title'),
                  props: {
                    span: 24,
                    isPaper: true,
                    titleType: 'heading',
                  },
                  layout: [
                    {
                      type: 'row',
                      props: {},
                      fields: [
                        {
                          type: 'custom',
                          name: 'contact',
                          component: () => UserSelector,
                          props: {
                            type: 'contact',
                            disabled: ({ state }) => !state.user.existingId,
                            readOnly: ({ state }) => state.status === WalkingStatuses.walking
                              || state.status === WalkingStatuses.completed
                              || state.status === WalkingStatuses.canceled,
                          },
                          validation: [
                            {
                              type: 'empty-required-user' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'column',
          props: {},
          layout: [
            {
              type: 'column',
              props: {
                span: 24,
                xs: 24,
              },
              fields: [
                {
                  type: 'custom',
                  name: 'walker',
                  component: () => WalkerManualTable,
                  props: {
                    type: 'walker',
                    hidden: ({ state, isNew }) => state.status === WalkingStatuses.walking
                      || state.status === WalkingStatuses.completed
                      || state.status === WalkingStatuses.canceled
                      || isNew,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
