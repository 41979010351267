import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { servicesT } from './index';
import { t } from './ru';
import {
  get, create, update, remove,
} from './requests';
import type { OfferEdit } from './types';

export const cardConfig = cardPageController<OfferEdit>({
  header: {
    title: servicesT('card.title'),
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'services' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'link',
        to: (route): any => ({ name: 'services-edit', params: { route } }),
        props: {
          text: baseT('form.edit_button'),
          class: 'width-auto',
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** Category, City, Status */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'select',
                  name: 'category',
                  options: [],
                  label: baseT('label.category'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.label,
                  },
                },
                {
                  type: 'select',
                  name: 'city',
                  options: [],
                  label: baseT('label.city'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.name,
                  },
                },
                {
                  type: 'select',
                  name: 'status',
                  options: [],
                  label: baseT('label.status'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.label,
                  },
                },
              ],
            },
            /** Name, Description */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'title',
                  label: baseT('label.name'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'description',
                  label: servicesT('card.form.description.title'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },
            /** Max number of pets */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'maxNumberOfPets',
                  label: servicesT('card.form.max_number_of_pets'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },
            /** Tariff */
            {
              type: 'column',
              title: t('card.form.tariff.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'offerRates',
                  dataType: 'array',
                  props: {
                    readOnly: true,
                  },
                  layout: [
                    /** Time, price, walker fee */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'time',
                          label: t('card.form.tariff.time'),
                          dataType: 'string',
                          props: {
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'price',
                          label: t('card.form.tariff.price'),
                          dataType: 'string',
                          props: {
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'walkerFee',
                          label: t('card.form.tariff.walker_fee'),
                          dataType: 'string',
                          props: {
                            readOnly: true,
                          },
                        },
                      ],
                    },
                    /** Hidden input */
                    {
                      type: 'column',
                      props: {},
                      fields: [
                        {
                          type: 'input',
                          name: 'title',
                          dataType: 'string',
                          props: {
                            hidden: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            /** Types */
            {
              type: 'column',
              title: t('card.form.type'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'offerTypes',
                  dataType: 'array',
                  props: {
                    readOnly: true,
                  },
                  layout: [
                    /** Title */
                    {
                      type: 'row',
                      props: {
                        align: 'top',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'title',
                          dataType: 'string',
                          props: {
                            readOnly: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            /** Options */
            {
              type: 'column',
              title: t('card.form.options.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'offerOptions',
                  dataType: 'array',
                  props: {
                    readOnly: true,
                  },
                  layout: [
                    /** Title, max number of pets */
                    {
                      type: 'row',
                      props: {
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'title',
                          dataType: 'string',
                          label: servicesT('card.form.options.name'),
                          props: {
                            readOnly: true,
                          },
                        },

                        {
                          type: 'input',
                          name: 'maxNumberOfPets',
                          dataType: 'string',
                          label: servicesT(
                            'card.form.options.max_number_of_pets',
                          ),
                          props: {
                            readOnly: true,
                          },
                        },
                      ],
                    },
                    /** Prompt */
                    {
                      type: 'column',
                      props: {},
                      fields: [
                        {
                          type: 'input',
                          name: 'prompt',
                          dataType: 'string',
                          label: servicesT('card.form.options.prompt'),
                          props: {
                            readOnly: true,
                          },
                        },
                      ],
                    },
                    /** Price, walker fee */
                    {
                      type: 'row',
                      props: {
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'price',
                          dataType: 'string',
                          label: servicesT('card.form.options.price'),
                          props: {
                            readOnly: true,
                          },
                        },

                        {
                          type: 'input',
                          name: 'walkerFee',
                          dataType: 'string',
                          label: servicesT('card.form.options.walker_fee'),
                          props: {
                            readOnly: true,
                          },
                        },
                      ],
                    },

                    /** Hidden input */
                    {
                      type: 'column',
                      props: {},
                      fields: [
                        {
                          type: 'input',
                          name: 'title',
                          dataType: 'string',
                          props: {
                            hidden: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
