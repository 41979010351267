<template>
  <div class="user-select">
    <MagnerFormRadio
      v-model="user.type"
      :field="{
        props: {
          class: 'ml-4',
          readOnly: readOnly,
          readOnlyFormatter: (val) => {}
        },
        options: [{
          label: 'Новый',
          value: 'new',
        }, {
          label: 'Есть в базе',
          value: 'existing',
        }],
      }"
    />

    <div class="user-select__blocks">
      <div v-if="user.type === 'existing'">
        <MagnerFormSelect
          v-if="hasSelect && !readOnly"
          :key="selectKey"
          v-model="user.existingId"
          :field="{
            props: {
              disabled: isEmptyContacts || field.disabled,
              placeholder: selectPlaceholder,
              valueKey: 'id',
              labelFormatter: (option) => `${option.phone} — ${option.name} ${option.surname} ${option.deactivated || ''}`,
              filterable: true,
              remote: true,
              noDataText: 'Ничего не найдено',
              remoteMethod,
              loadRemoteMethodOnFocus: true,
            },
          }"
          @update:model-value="updateExisting"
        />
      </div>

      <template v-else>
        <div :class="{ 'is-success': user.newEntity.phone && regexPhone.test(user.newEntity.phone) }">
          <p>
            Телефон
            <sup v-if="(userType === 'client' && $route.fullPath.includes('new')) || userType === 'contact'">
              *
            </sup>
          </p>
          <MagnerFormInput
            v-model="user.newEntity.phone"
            :field="fields.phone"
            @update:model-value="updateValue"
          />
        </div>
        <div :class="{ 'is-success': user.newEntity.name }">
          <p>
            Имя
            <sup v-if="(userType === 'client' && $route.fullPath.includes('new')) || userType === 'contact'">
              *
            </sup>
          </p>
          <MagnerFormInput
            v-model="user.newEntity.name"
            :field="fields.name"
            @update:model-value="updateValue"
          />
        </div>
        <div :class="{ 'is-success': user.newEntity.surname }">
          <p>
            Фамилия
            <sup v-if="(userType === 'client' && $route.fullPath.includes('new')) || userType === 'contact'">
              *
            </sup>
          </p>
          <MagnerFormInput
            v-model="user.newEntity.surname"
            :field="fields.surname"
            @update:model-value="updateValue"
          />
        </div>
      </template>
    </div>

    <div
      v-if="user.type === 'existing' && user.existingEntity"
      :key="user.existingEntity.phone"
      class="user-select__blocks user-select__blocks_big-padding"
    >
      <div>
        <p>Телефон</p>
        <MagnerFormInput
          :model-value="user.existingEntity.phone"
          :field="fields.phoneReadonly"
        />
      </div>
      <div>
        <p>Имя</p>
        <MagnerFormInput
          :model-value="user.existingEntity.name"
          :field="fields.nameReadonly"
        />
      </div>
      <div>
        <p>Фамилия</p>
        <MagnerFormInput
          :model-value="user.existingEntity.surname"
          :field="fields.surnameReadonly"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  reactive,
  computed,
  watch,
  ref,
} from 'vue';
import { MagnerFormRadio, MagnerFormSelect, MagnerFormInput } from 'magner';
import { type Client, clientSearch, clientSimpleGet } from 'features/users/clients';
import { walkerSearch, walkerGet } from 'features/users/walkers';
import { DictionaryItem } from '~/types';
import { regexPhone } from '~/configs/development/validation';

type UserSelectorType = 'client' | 'walker' | 'contact';

const props = defineProps({
  form: {
    type: Object as PropType<Record<string, any>>,
    required: true,
  },
  field: {
    type: Object as PropType<{
      type?: UserSelectorType,
      disabled: boolean,
      readOnly: any,
    }>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Array, Object] as PropType<DictionaryItem>,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }
  return props.field.readOnly;
});

const userType = computed<UserSelectorType>(() => props.field?.type || 'client');
const hasSelect = computed<boolean>(() => (userType.value === 'client' && window.location.href.includes('new')) || userType.value !== 'client');

const user = reactive({
  type: props.modelValue?.type ? props.modelValue.type : 'new' as 'new' | 'existing',
  existingId: props.modelValue?.existingId ? props.modelValue.existingId : null as string | null,
  existingEntity: props.modelValue?.existingEntity ? props.modelValue.existingEntity : null as null | Client,
  existingData: props.modelValue?.existingData,
  newEntity: { phone: '', name: '', surname: '' },
});

const isEmptyContacts = ref<boolean>(false);
const selectKey = computed(() => {
  if (userType.value === 'client') {
    return 'key';
  }

  return props.form.user.existingId + isEmptyContacts.value;
});

const formatterOptions = (options: any) => {
  if (!user.existingId) {
    return options;
  }

  // const hasOption = options.some((item: Client) => item.id === user.existingId);

  // if (!hasOption) {
  //   return [...options, { ...user.existingEntity, disabled: true, deactivated: '(неактивный)' }];
  // }

  return options;
};

const remoteMethod = {
  client: async (value: string) => {
    const list = await clientSearch(value);

    return { data: formatterOptions(list?.data || []) };
  },
  walker: async (value: string) => {
    const list = await walkerSearch(value);

    return { data: formatterOptions(list?.data || []) };
  },
  contact: async () => {
    const clientData = await clientSimpleGet({ id: props.form.user.existingId, isNew: false, data: {} as any });

    if (clientData.data) {
      isEmptyContacts.value = !clientData.data.contacts?.length;
      return { data: formatterOptions(clientData.data.contacts) };
    }
    isEmptyContacts.value = true;
    return { data: [] };
  },
}[userType.value];

const simpleGetMethod = {
  client: clientSimpleGet,
  walker: walkerGet,
  contact: async () => {
    const clientData = await clientSimpleGet({ id: props.form.user.existingId, isNew: false, data: {} as any });
    if (clientData.data) {
      return { data: clientData.data.contacts };
    }
    return { data: [] };
  },
}[userType.value];

const updateValue = () => {
  emit('update:modelValue', user);
};

const updateExisting = async (id: string) => {
  const fullUser = await simpleGetMethod({ id: user.existingId, isNew: false, data: {} as any });

  if (fullUser?.error) {
    user.existingEntity = null;
    emit('update:modelValue', user);
    return;
  }

  if (Array.isArray(fullUser?.data)) {
    user.existingEntity = fullUser?.data.find((user) => user.id === id);
  }
  else {
    user.existingEntity = fullUser?.data;
  }

  updateValue();
};

watch(() => props.modelValue, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true });

watch(() => user.type, () => {
  user.existingId = null;
  user.existingEntity = null;
  updateValue();
});

// Отслеживаем изменения id у выбранного клиента и делаем изменения
watch(() => props.form.user.existingId, () => {
  // Сбрасываем значение контактов при смене клиента
  if (props.field?.type === 'contact') {
    user.existingId = null;
    user.existingEntity = null;
    updateValue();
  }
});

const selectPlaceholder = computed(() => {
  if (userType.value === 'contact') {
    if (props.form.user.type === 'new') {
      return 'Для нового клиента нет контактов в базе';
    }

    if (!props.form.user.existingId) {
      return 'Выберите клиента из базы';
    }

    if (props.form.user.existingId && isEmptyContacts.value) {
      return 'Контактов у выбранного клиента не найдено';
    }
  }

  return 'Выбрать';
});

const fields = {
  phone: {
    props: {
      placeholder: '+7-900-000-00-00',
      mask: {
        mask: '+7T#########',
        tokens: { T: { pattern: '[0|1|3|4|5|6|7|9]' } },
      },
    },
  },
  name: {
    props: {
      placeholder: 'Иван',
    },
  },
  surname: {
    props: {
      placeholder: 'Иванов',
    },
  },
  phoneReadonly: {
    props: {
      placeholder: '+7-900-000-00-00',
      readOnly: true,
      mask: {
        mask: '+7T#########',
        tokens: { T: { pattern: '[0|1|3|4|5|6|7|9]' } },
      },
    },
  },
  nameReadonly: {
    props: {
      placeholder: 'Иван',
      readOnly: true,
    },
  },
  surnameReadonly: {
    props: {
      placeholder: 'Иванов',
      readOnly: true,
    },
  },
};
</script>

<style scoped>
.user-select {
  width: 100%;
}

.user-select__blocks {
  display: flex;
  gap: 12px;
  padding-top: 8px;
}

.user-select__blocks_big-padding {
  padding-top: 18px;
}

.user-select__blocks p {
  margin: 0 0 8px;
}

.user-select__blocks > div {
  width: 100%;
}

sup {
  color: red;
  opacity: .6;
  font-size: 14px;
}
</style>

<style>
.input-user .is-success .el-input__wrapper {
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset !important;
}
</style>
