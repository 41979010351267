export const statuses = [
  {
    label: 'Активен',
    value: 'active',
  },
  {
    label: 'Неактивен',
    value: 'inactive',
  },
];

export const statusesBoolean = [
  {
    label: 'Активен',
    value: true,
  },
  {
    label: 'Неактивен',
    value: false,
  },
];

export const statusesFilters = [
  {
    label: 'Активен',
    value: 'true',
  },
  {
    label: 'Неактивен',
    value: 'false',
  },
];

export const audienceList = [
  {
    label: 'Сегмент',
    value: 'segment',
  },
  {
    label: 'Конкретный клиент',
    value: 'clients',
  },
];

export const objectList = [
  {
    label: 'На Чек',
    value: 'check',
  },
  {
    label: 'На услугу',
    value: 'offer',
  },
  {
    label: 'На наценки',
    value: 'rates',
  },
  {
    label: 'На опции',
    value: 'options',
  },
];

export const validPeriodList = [
  {
    label: 'Месяц рождения питомца',
    value: 'petBirthday',
  },
  {
    label: 'Период',
    value: 'period',
  },
  {
    label: 'Без ограничений',
    value: 'all',
  },
];

export const discountTypeList = [
  {
    label: 'В рублях',
    value: 'ruble',
  },
  {
    label: 'В процентах',
    value: 'percent',
  },
];

export const promoList = [
  {
    label: 'В приложении',
    value: 'app',
  },
  {
    label: 'Промокод',
    value: 'promo',
  },
];

export const transitionList = [
  {
    label: 'По ссылке',
    value: 'link',
  },
  {
    label: 'Внутри приложения',
    value: 'app',
  },
];

export const adsPushTypesList = [
  {
    label: 'День рождения питомца',
    value: 'birthday',
  },
  {
    label: 'Разовая',
    value: 'once',
  },
];

export const adsPushStatusesList = [
  {
    label: 'Отправлено',
    value: 'sent',
  },
  {
    label: 'Рассылается',
    value: 'active',
  },
  {
    label: 'Запланировано',
    value: 'planned',
  },
  {
    label: 'Неактивно',
    value: 'cancelled',
  },
];
