import { ft } from '~/utils/feature-translate';

export const CitiesRu = {
  sidebar: {
    table_title: 'Города',
    card_title: 'Город',
  },
  table: {
    title: 'Города',
    not_found: 'Города не найдены',
    headers: {
      name: 'Город',
      telegram: 'Telegram',
      phone: 'Телефон',
      status: 'Статус',
    },
  },
  card: {
    title: 'Город',
    title_district: 'Рабочие зоны',

    form: {
      city: {
        title: 'Город',
        placeholder: 'Выберите город',
      },
      telegram: {
        title: 'Telegram',
        placeholder: 'Введите значение',
      },
      phone: {
        title: 'Телефон',
        placeholder: '+7...',
      },
      status: 'Статус',
      map: 'Рабочие зоны',
    },
  },
};

export const t = ft(CitiesRu, 'settings.cities.');
