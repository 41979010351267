import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import WalkingStatusTagCard from 'features/orders/components/walking-status-tag-card.vue';
import ProcessingManager from 'features/orders/components/processing-manager.vue';
import WalkServices from 'features/orders/components/walk-services.vue';
import OrderRating from 'features/orders/components/order-rating.vue';
import { notificationTypes, paymentTypes, searchCities } from '~/utils/searchInit';
import {
  get,
  attachOrder,
  unpinFromAdmin,
} from './requests';
import type { OrderFrontend } from './types';
import { t } from './ru';
import { Pet, WalkingStatuses } from '~/types/common';
import { DictionaryItem } from '~/types';
import { ROLE } from '~/constants';
import { getPageAge } from '~/utils/get-page-age';
import WalkersComments from '~/components/walkers-comments.vue';

export const cardConfig = cardPageController<OrderFrontend>({
  getRequest: get,

  header: {
    title: 'Заказ',
    tabs: [
      {
        label: 'Карточка',
        link: (route) => ({ name: 'order', params: { id: route.params.id } }),
        active: true,
      },
      {
        label: 'Чат',
        link: (route) => ({
          name: 'chats-view',
          params: { id: route.params.id },
        }),
        active: false,
      },
      {
        label: 'Отчет',
        link: (route) => ({
          name: 'order-report',
          params: { id: route.params.id },
        }),
        active: false,
      },
    ],
  },

  form: {
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'orders' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        props: {
          type: 'primary',
          hidden: ({ state }) => state.status === WalkingStatuses.completed
            || state.status === WalkingStatuses.canceled
            || (state.processingManager?.id
              ? JSON.parse(localStorage.getItem('magner-petshop') || '')?.id
                !== state.processingManager?.id
              : ''),
          text: baseT('form.start_button'),
        },
        action: attachOrder,
      },
      {
        type: 'action',
        props: {
          plain: true,
          type: 'primary',
          text: baseT('form.unpin_button'),
          hidden: ({ role, state }) => role === ROLE.MANAGER || !state.processingManager?.id,
        },
        action: unpinFromAdmin,
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
            span: 24,
            xs: 24,
          },
          fields: [
            {
              type: 'custom',
              name: 'processingManager',
              component: () => ProcessingManager,
              props: {},
            },
            {
              type: 'custom',
              name: 'status',
              component: () => WalkingStatusTagCard,
              props: {},
            },
          ],
        },
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              layout: [
                /** Date and Time of walking, Asap */
                {
                  type: 'row',
                  props: {
                    elementsGrow: true,
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'datetime',
                      name: 'startTime',
                      label: t('form.fields.walkDate'),
                      dataType: 'date',
                      props: {
                        type: 'datetime',
                        format: 'DD.MM.YYYY',
                        valueFormat: 'YYYY-MM-DD',
                        placeholder: t('form.fields.walkDate'),
                        readOnly: true,
                        readOnlyFormatter: (val) => new Date(val as string)
                          .toLocaleDateString()
                          .replaceAll('/', '.'),
                      },
                    },
                    {
                      type: 'datetime',
                      name: 'startTime',
                      label: t('form.fields.walkTime'),
                      dataType: 'date',
                      props: {
                        type: 'time',
                        format: 'DD.MM.YYYY HH:mm',
                        valueFormat: 'YYYY-MM-DD HH:mm',
                        placeholder: t('form.fields.walkTime'),
                        readOnly: true,
                        readOnlyFormatter: (val) => new Date(val as string)
                          .toLocaleTimeString('ru-RU')
                          .slice(0, -3),
                      },
                    },
                    {
                      type: 'input',
                      name: 'asap',
                      label: t('form.fields.urgent'),
                      dataType: 'string',
                      props: {
                        readOnly: true,
                        readOnlyFormatter: (val) => (val === 1 ? 'Да' : 'Нет'),
                      },
                    },
                  ],
                },
                /** City, Communication, Payment Method */
                {
                  type: 'row',
                  props: {
                    elementsGrow: true,
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'select',
                      name: 'city',
                      options: [],
                      label: t('form.fields.city'),
                      dataType: 'string',
                      changeAction: ({ form }) => {
                        form.district = { city: form.city, district: null };
                      },
                      props: {
                        valueKey: 'id',
                        labelKey: 'name',
                        placeholder: t('form.fields.city'),
                        filterable: true,
                        remote: true,
                        remoteMethod: searchCities,
                        disabled: true,
                        class: 'fake-read-only',
                      },
                    },
                    {
                      type: 'select',
                      name: 'notificationType',
                      options: [],
                      label: t('form.fields.communication.label'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.fields.communication.placeholder'),
                        remote: true,
                        remoteMethod: notificationTypes,
                        readOnly: true,
                        readOnlyFormatter: (val) => val.label,
                      },
                    },
                    {
                      type: 'select',
                      name: 'paymentType',
                      options: [],
                      label: t('form.fields.payment.label'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.fields.payment.placeholder'),
                        remote: true,
                        remoteMethod: paymentTypes,
                        readOnly: true,
                        readOnlyFormatter: (val) => val.label,
                      },
                    },
                  ],
                },
                /** externalId, Source, Payment Status */
                {
                  type: 'row',
                  props: {
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                    elementsGrow: true,
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'externalId',
                      label: t('form.fields.externalId'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.fields.externalId'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'select',
                      name: 'source',
                      options: [],
                      label: t('form.fields.source.label'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.fields.source.placeholder'),
                        readOnly: true,
                        readOnlyFormatter: (val) => val.label,
                      },
                    },

                    {
                      type: 'select',
                      name: 'isPaid',
                      options: [
                        {
                          value: true,
                          label: 'Оплачено',
                        },
                        {
                          value: false,
                          label: 'Не оплачено',
                        },
                      ],
                      label: t('form.fields.paymentState'),
                      dataType: 'boolean',
                      props: {
                        placeholder: t('form.fields.paymentState'),
                        readOnly: true,
                        readOnlyFormatter: (val) => (val ? 'Оплачено' : 'Не оплачено'),
                      },
                    },
                  ],
                },
                /** Reason canceled */
                {
                  type: 'row',
                  props: {
                    styles: {
                      gap: '12px',
                      'flex-wrap': 'nowrap',
                    },
                    elementsGrow: true,
                    hidden: ({ state }) => !state.cancelReason,
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'cancelReason',
                      label: t('form.fields.reason'),
                      dataType: 'string',
                      props: {
                        hidden: ({ state }) => !state.cancelReason,
                        readOnly: true,
                        readOnlyFormatter: (value: any) => (value.comment ? value.comment : value.reason.label),
                      },
                    },
                  ],
                },
                /** SEVICES */
                {
                  type: 'column',
                  props: {
                    span: 24,
                    xs: 24,
                    isPaper: true,
                    titleType: 'heading',
                  },
                  fields: [
                    {
                      type: 'custom',
                      name: 'walkingType',
                      dataType: 'object',
                      component: () => WalkServices,
                      props: {
                        disabled: true,
                      },
                    },
                  ],
                },
                /** PETS */
                {
                  type: 'column',
                  title: t('form.pets.title'),
                  props: {
                    span: 24,
                    isPaper: true,
                    titleType: 'heading',
                  },
                  fields: [
                    {
                      type: 'collection',
                      name: 'pets',
                      dataType: 'array',
                      props: {
                        readOnly: true,
                      },
                      layout: [
                        {
                          type: 'row',
                          props: {
                            justify: 'space-between',
                            elementsGrow: true,
                            styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                          },
                          fields: [
                            {
                              type: 'input',
                              name: 'name',
                              label: t('form.pets.fields.name'),
                              dataType: 'string',
                              props: {
                                placeholder: t(
                                  'form.pets.fields.namePlaceholder',
                                ),
                                readOnly: true,
                              },
                            },
                            {
                              type: 'input',
                              name: 'breed',
                              label: t('form.pets.fields.breed'),
                              dataType: 'string',
                              props: {
                                placeholder: t(
                                  'form.pets.fields.breedPlaceholder',
                                ),
                                readOnly: true,
                                readOnlyFormatter: (val) => val || 'Без породы',
                              },
                            },
                          ],
                        },
                        {
                          type: 'row',
                          props: {
                            justify: 'space-between',
                            elementsGrow: true,
                            styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                          },
                          fields: [
                            {
                              type: 'input',
                              name: 'age',
                              label: t('form.pets.fields.age'),
                              dataType: 'string',
                              props: {
                                placeholder: t(
                                  'form.pets.fields.agePlaceholder',
                                ),
                                readOnly: true,
                              },
                            },
                            {
                              type: 'input',
                              name: 'weight',
                              label: t('form.pets.fields.weight'),
                              dataType: 'string',
                              props: {
                                placeholder: t(
                                  'form.pets.fields.weightPlaceholder',
                                ),
                                readOnly: true,
                                readOnlyFormatter: (val) => `${val} кг`,
                              },
                            },
                            {
                              type: 'input',
                              name: 'sex',
                              label: t('form.pets.fields.gender'),
                              dataType: 'string',
                              props: {
                                placeholder: t(
                                  'form.pets.fields.genderPlaceholder',
                                ),
                                readOnly: true,
                                readOnlyFormatter: (val) => (val === 'm' ? 'Мальчик' : 'Девочка'),
                              },
                            },
                          ],
                        },
                        {
                          type: 'row',
                          props: {
                            elementsGrow: true,
                          },
                          fields: [
                            {
                              type: 'input',
                              name: 'peculiarities',
                              label: t('form.pets.fields.peculiarities'),
                              dataType: 'string',
                              props: {
                                readOnly: true,
                                hidden: (_, petInstance?: Pet) => !petInstance?.peculiarities?.length,
                                readOnlyFormatter: (val) => {
                                  const value = val as Pet['peculiarities'] as DictionaryItem[];
                                  return `${value
                                    .map((item) => item.title)
                                    .join(', ')}`;
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'row',
                          props: {
                            elementsGrow: true,
                          },
                          fields: [
                            {
                              type: 'input',
                              name: 'comment',
                              label: 'Комментарий владельца',
                              dataType: 'string',
                              props: {
                                readOnly: true,
                              },
                            },
                            {
                              type: 'custom',
                              name: 'id',
                              props: {
                                hidden: (_, petInstance?: Pet) => !petInstance.id,
                                readOnly: true,
                              },
                              component: () => WalkersComments,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                /** PAYMENTS */
                {
                  type: 'column',
                  title: t('form.payments.title'),
                  props: {
                    span: 24,
                    isPaper: true,
                    titleType: 'heading',
                    hidden: ({ state, isNew }) => !state.payments.length || isNew,
                  },
                  fields: [
                    {
                      type: 'collection',
                      name: 'payments',
                      dataType: 'array',
                      props: {
                        readOnly: true,
                      },
                      layout: [
                        /** Number, Amount, Status */
                        {
                          type: 'row',
                          props: {
                            align: 'bottom',
                            elementsGrow: true,
                            styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                          },
                          fields: [
                            {
                              type: 'input',
                              name: 'id',
                              label: t('form.payments.fields.number'),
                              dataType: 'string',
                              props: {
                                placeholder: t('form.payments.fields.number'),
                                readOnly: true,
                              },
                            },
                            {
                              type: 'input',
                              name: 'amount',
                              label: t('form.payments.fields.amount'),
                              dataType: 'string',
                              props: {
                                placeholder: t('form.payments.fields.amount'),
                                readOnly: true,
                                readOnlyFormatter: (val) => `${val} ₽`,
                              },
                            },
                            {
                              type: 'input',
                              name: 'status',
                              label: t('form.payments.fields.status'),
                              dataType: 'string',
                              props: {
                                placeholder: t('form.payments.fields.status'),
                                readOnly: true,
                              },
                            },
                          ],
                        },
                        /** createdAt, expireAt */
                        {
                          type: 'row',
                          props: {
                            align: 'bottom',
                            elementsGrow: true,
                            styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                          },
                          fields: [
                            {
                              type: 'datetime',
                              name: 'createdAt',
                              label: t('form.payments.fields.createdAt'),
                              dataType: 'date',
                              props: {
                                type: 'date',
                                format: 'DD.MM.YYYY HH:mm',
                                placeholder: t(
                                  'form.payments.fields.createdAt',
                                ),
                                readOnly: true,
                                readOnlyFormatter: (val) => new Date(val as string).toLocaleString(
                                  'ru-RU',
                                ),
                              },
                            },
                            {
                              type: 'datetime',
                              name: 'expireAt',
                              label: t('form.payments.fields.expireAt'),
                              dataType: 'date',
                              props: {
                                type: 'date',
                                format: 'DD.MM.YYYY HH:mm',
                                placeholder: t('form.payments.fields.expireAt'),
                                readOnly: true,
                                readOnlyFormatter: (val) => new Date(val as string).toLocaleString(
                                  'ru-RU',
                                ),
                              },
                            },
                          ],
                        },
                        /** Payment Link */
                        {
                          type: 'row',
                          props: {
                            class: 'word-break_break-all',
                          },
                          fields: [
                            {
                              type: 'input',
                              name: 'formUrl',
                              label: t('form.payments.fields.paymentLink'),
                              dataType: 'string',
                              props: {
                                placeholder: t(
                                  'form.payments.fields.paymentLink',
                                ),
                                readOnly: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            /** RATING */
            {
              type: 'column',
              props: {
                span: 24,
                xs: 24,
                isPaper: true,
                titleType: 'heading',
                // View column only 'completed' status and user set rating
                hidden: ({ state }) => (state.status === WalkingStatuses.completed
                  ? !state.rating?.rating
                  : true),
              },
              fields: [
                {
                  type: 'custom',
                  name: 'rating',
                  dataType: 'object',
                  component: () => OrderRating,
                  props: {},
                },
              ],
            },
            /** CLIENT */
            {
              type: 'column',
              title: t('form.client.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              layout: [
                /** Nаme, Surname, Phone */
                {
                  type: 'row',
                  props: {
                    align: 'bottom',
                    elementsGrow: true,
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'userPhone',
                      label: t('form.client.fields.phone'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.client.fields.phone'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'userName',
                      label: t('form.client.fields.name'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.client.fields.name'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'userSurname',
                      label: t('form.client.fields.surname'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.client.fields.surname'),
                        readOnly: true,
                      },
                    },
                  ],
                },
              ],
            },
            /** WALKER */
            {
              type: 'column',
              title: t('form.walker.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              layout: [
                /** Nаme, Surname, Phone */
                {
                  type: 'row',
                  props: {
                    align: 'bottom',
                    elementsGrow: true,
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'walkerPhone',
                      label: t('form.walker.fields.phone'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.walker.fields.phone'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'walkerName',
                      label: t('form.walker.fields.name'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.walker.fields.name'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'walkerSurname',
                      label: t('form.walker.fields.surname'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.walker.fields.surname'),
                        readOnly: true,
                      },
                    },
                  ],
                },
              ],
            },
            /** ADDRESS */
            {
              type: 'column',
              title: t('form.address.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              layout: [
                /** City, District */
                {
                  type: 'row',
                  props: {
                    align: 'bottom',
                    elementsGrow: true,
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'userCity',
                      label: t('form.address.fields.city'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.address.fields.city'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'userDistrict',
                      label: t('form.address.fields.district'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.address.fields.district'),
                        readOnly: true,
                      },
                    },
                  ],
                },
                /** Street, House */
                {
                  type: 'row',
                  props: {
                    align: 'bottom',
                    elementsGrow: true,
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'userStreet',
                      label: t('form.address.fields.street'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.address.fields.street'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'userHouse',
                      label: t('form.address.fields.house'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.address.fields.house'),
                        readOnly: true,
                      },
                    },
                  ],
                },
                /** Entrance, Floor, Flat, DoorCode */
                {
                  type: 'row',
                  props: {
                    justify: 'space-between',
                    elementsGrow: true,
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'userEntrance',
                      label: t('form.address.fields.porch'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.address.fields.porch'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'userFloor',
                      label: t('form.address.fields.floor'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.address.fields.floor'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'userOffice',
                      label: t('form.address.fields.flat'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.address.fields.flat'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'userDoorCode',
                      label: t('form.address.fields.intercom'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.address.fields.intercom'),
                        readOnly: true,
                      },
                    },
                  ],
                },
                /** Comment */
                {
                  type: 'row',
                  props: {
                    justify: 'space-between',
                    elementsGrow: true,
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'userAddressComment',
                      label: t('form.address.fields.comment'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.address.fields.comment'),
                        readOnly: true,
                      },
                    },
                  ],
                },
              ],
            },
            /** CONTACT */
            {
              type: 'column',
              title: t('form.contact.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              layout: [
                /** Nаme, Surname, Phone */
                {
                  type: 'row',
                  props: {
                    align: 'bottom',
                    elementsGrow: true,
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'contactPhone',
                      label: t('form.contact.fields.phone'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.contact.fields.phone'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'contactName',
                      label: t('form.contact.fields.name'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.contact.fields.name'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'contactSurname',
                      label: t('form.contact.fields.surname'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.contact.fields.surname'),
                        readOnly: true,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
