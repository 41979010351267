import axios from 'axios';
import { request } from '~/utils/request';
import { init } from '~/utils/get-init';
import {
  Roles, AdminStatus, District, WalkingType, 
} from '~/types/common';

const DADATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
const DADATA_TOKEN = 'TOKEN ff31bdf42d480f28189542de3402bd603750535b';

export const getNotifyDirection = () => init.get().notifyDirection;

export const searchCities = request.custom(async ({ data }) => ({
  data: init.get().cities
    .filter((city) => (city.name).toLowerCase().includes(data.search.toLowerCase())),
}));

export const searchDistrict = (id: string | null) => request.custom(async ({ data }) => ({
  data: (init.get().cities.find((city) => city.id === id)?.districts || [])
    .filter((district) => (district.slug).toLowerCase().includes((data.search || '').toLowerCase())),
}));

// Функция для поиска районов в заказах
export const cardSearchDistricts = request.custom(async ({ data }) => {
  const initCities = init.get().cities;

  if (data.form.city) {
    const selectCity = initCities.find((initCity) => initCity.id === data.form.city);

    if (selectCity) {
      if (selectCity?.districts.length) {
        const res = selectCity?.districts
          .filter((district) => (district.slug).toLowerCase().includes((data.search || '').toLowerCase()));

        return { data: res };
      }

      return { data: [{ id: 1, slug: 'Районов в выбранном городе не найдено', disabled: true }] };
    }
  }

  return { data: [{ id: 1, slug: 'Выберите город', disabled: true }] };
});

// Функция для поиска районов в карточке клиента
export const clientCardSearchDistricts = request.custom(async ({ data }) => {
  const initCities = init.get().cities;

  if (data.currentItem.city) {
    const selectCity = initCities.find((initCity) => initCity.id === data.currentItem.city);

    if (selectCity) {
      if (selectCity?.districts.length) {
        const res = selectCity?.districts
          .filter((district) => (district.slug).toLowerCase().includes((data.search || '').toLowerCase()));

        return { data: res };
      }

      return { data: [{ id: 1, slug: 'Районов в выбранном городе не найдено', disabled: true }] };
    }
  }

  return { data: [{ id: 1, slug: 'Выберите город', disabled: true }] };
});

// Функция для поиска районов в фильтрах в таблице клиентов и заказов
export const filterSearchDistricts = request.custom(async ({ data }) => {
  const districts: District[] = [];
  const initCities = init.get().cities;

  if (data.form.city?.length) {
    initCities.forEach((initCity) => {
      if (data.form.city.includes(initCity.id)) {
        districts.push(...initCity.districts);
      }
    });

    const res = districts.filter((district) => (district.slug).toLowerCase().includes((data.search || '').toLowerCase()));

    return { data: res };
  }

  return { data: [{ id: 1, slug: 'Выберите город', disabled: true }] };
});

export const searchPeculiarities = request.custom(async ({ data }) => ({
  data: init.get().petPeculiarities
    .filter((pecularity) => (pecularity.title).toLowerCase().includes(data.search.toLowerCase())),
}));

export const searchBreed = request.custom(async ({ data }) => {
  const petBreeds = init.get().petBreeds;

  // Вынужденный фикс для того, чтобы "Без породы" можно было отправить в фильтрах
  petBreeds.forEach((item) => {
    if (item.id === null) item.id = 'null';
  });

  return {
    data: petBreeds.filter((breed) => (breed.title).toLowerCase().includes(data.search.toLowerCase())),
  };
});

export const searchBreedAll = request.custom(async ({ data }) => ({
  data: init.get().allPetBreeds.filter((item) => (item.id !== null)).filter((breed) => (breed.title).toLowerCase().includes(data.search.toLowerCase())),
}));

export const searchStatuses = request.custom(async () => ({
  data: init.get().walkingStatuses,
}));

export const searchOffers = request.custom(async () => ({
  data: init.get().offers,
}));

export const cititesOptions = request.custom(async () => ({
  data: init.get().cities,
}));

export const notificationTypes = request.custom(async () => ({
  data: init.get().notificationTypes,
}));

export const paymentTypes = request.custom(async () => ({
  data: init.get().paymentTypes,
}));

export const searchSource = request.custom(async () => ({
  data: init.get().sources,
}));

export const searchOfferStatuses = request.custom(async () => ({
  data: init.get().offerStatuses,
}));

export const deepLinks = request.custom(async () => ({
  data: init.get().deepLinks,
}));

export const offerCategories = request.custom(async () => ({
  data: init.get().offerCategories,
}));

export const adminStatuses = request.custom(async () => ({
  data: init.get().adminStatuses || [
    {
      label: 'Активный',
      value: AdminStatus.ACTIVE,
    },
    {
      label: 'Заблокирован',
      value: AdminStatus.BLOCKED,
    },
  ],
}));

export const userRoles = request.custom(async () => ({
  data: init.get().userRoles || [
    {
      label: 'Администратор',
      value: Roles.ADMIN,
    },
    {
      label: 'Менеджер',
      value: Roles.MANAGER,
    },
    {
      label: 'Маркетолог',
      value: Roles.MARKETER,
    },
  ],
}));

// Для MVP оставили
// 1.Чат на сайте
// 2.Заказ по телефону
// 3.Заказ по E-mail
export const searchSourceForCard = request.custom(async () => ({
  data: init.get().sources.filter((item) => item.value !== 'app' && item.value !== 'bot'),
}));

export const searchReasons = request.custom(async () => ({
  data: init.get().cancelReasons,
}));

/**
 * get walking types by city id
 * @param id – id of a particular city to search in
 */
export const searchWalkingTypes = (id: string | null) => request.custom(async ({ data }) => ({
  data: (init.get().cities.find((city) => city.id === id)?.walkingTypes || [])
    .filter((type) => (type.title).toLowerCase().includes((data.search || '').toLowerCase())),
}));

export const searchWalkingTypesForCity = request.custom(async ({ data }) => {
  if (data.form.city) {
    let walkingTypes: WalkingType[] = [];

    const activeCity = init.get().cities.find((city) => city.id === data.form.city);

    if (activeCity) {
      walkingTypes = activeCity.walkingTypes;
    }

    if (!walkingTypes.length) {
      return { data: [{ id: 1, title: 'Типов прогулки в выбранном городе не найдено', disabled: true }] };
    }

    const res = walkingTypes
      .filter((walkingTypes) => (walkingTypes.title)
        .toLowerCase().includes((data.search || '')
          .toLowerCase()));

    return { data: res };
  }

  return { data: [{ id: 1, title: 'Выберите город', disabled: true }] };
});

export const searchWalkingOptionsForCity = request.custom(async ({ data }) => {
  const defaultError = { data: [{ id: 1, title: 'Дополнительных опций не найдено', disabled: true }] };

  if (!data.form.city && !data.form.walkingType) {
    return { data: [{ id: 1, title: 'Выберите город и тип прогулки', disabled: true }] };
  }

  if (!data.form.city) {
    return { data: [{ id: 1, title: 'Выберите город', disabled: true }] };
  }

  if (!data.form.walkingType) {
    return { data: [{ id: 1, title: 'Выберите тип прогулки', disabled: true }] };
  }

  let walkingOptions = [];

  const activeCity = init.get().cities.find((city) => city.id === data.form.city);
  if (!activeCity) {
    return defaultError;
  }

  const activeWalkingType = activeCity.walkingTypes.find((item) => item.id === data.form.walkingType);
  if (!activeWalkingType) {
    return defaultError;
  }

  walkingOptions = activeWalkingType.options;

  if (!walkingOptions.length) {
    return { data: [{ id: 1, title: 'Дополнительных опций в выбранном городе и типом прогулки не найдено', disabled: true }] };
  }

  const res = walkingOptions
    .filter((item) => (item.title)
      .toLowerCase().includes((data.search || '')
        .toLowerCase()));

  return { data: res };
});

export const suggustedStreets = request.custom(async ({ data }) => {
  const value = data.currentItem || data.form;
  const cities = await cititesOptions('');

  const selectCity = cities.data?.find((item) => item.id === value.city || item.id === value.city);

  const cityName = selectCity?.name || '';
  const districtName = selectCity?.districts.find(
    (item) => item.id === data.district?.id || item.id === value.district,
  )?.slug || '';

  const query = `г ${cityName}, ${districtName} р-н, ${data.search}`;

  const result: any = await axios.post(
    DADATA_URL,
    { query },
    {
      headers: {
        Accept: 'application/json',
        Authorization: DADATA_TOKEN,
      },
    },
  );

  const res = result.data.suggestions
    .map((item: any) => ({
      value: item.data.street_with_type,
      label: item.data.street_with_type,
      data: item.data,
    }))
    .filter((item: any) => item.value)
    .reduce((acc: any, item: any) => {
      if (!acc.find((address: any) => address.value === item.value)) {
        acc.push(item);
      }

      return acc;
    }, []);

  return {
    data: res,
  };
});

export const getAddressData = request.custom(async ({ data }) => {
  const value = data.currentItem || data.form;
  const cities = await cititesOptions('');

  const selectCity = cities.data?.find(
    (item) => item.id === value.city || item.id === value.city,
  );

  const cityName = selectCity?.name || '';
  const districtName = selectCity?.districts.find(
    (item) => item.id === data.district?.id || item.id === value.district,
  )?.slug || '';

  const query = `г ${cityName}, ${districtName} р-н, ${value.street}`;
  
  const result: any = await axios.post(
    DADATA_URL,
    { query, count: 1 },
    {
      headers: {
        Accept: 'application/json',
        Authorization: DADATA_TOKEN,
      },
    },
  );

  return result.data.suggestions?.[0];
});
