/* eslint-disable max-len */
import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { walkersT } from 'features/users/walkers/index';
import WalkerRatingCard from 'features/users/walkers/components/walker-rating-card.vue';
import WalkerWorkingSchedule from './components/walker-working-schedule.vue';
import { t } from './ru';
import { Walkers, WalkerStatuses } from './types';
import { ValueLabel } from '~/types/common';
import {
  get, create, update,
} from './requests';
import { searchCities } from '~/utils/searchInit';

export const cardConfig = cardPageController<Walkers>({
  header: {
    title: 'Выгульщик',
    tag: {
      name: 'tag',
      props: {
        type: 'warning',
        size: 'large',
      },
    },
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,

  form: {
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'walkers' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'link',
        to: (route) => ({ name: 'walker-edit', params: { route } }),
        props: {
          text: baseT('form.edit_button'),
          class: 'width-auto',
          type: 'primary',
          hidden: ({ state }) => state.status.value === WalkerStatuses.declined
            || state.status.value === WalkerStatuses.removed,
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** Name, id, rating */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'name',
                      label: walkersT('form.fields.name.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: walkersT('form.fields.name.placeholder'),
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'id',
                      label: walkersT('form.fields.id'),
                      dataType: 'string',
                      props: {
                        placeholder: walkersT('form.fields.id'),
                        disabled: true,
                        hidden: true,
                      },
                    },

                    {
                      type: 'input',
                      name: 'profileDataOnModeration',
                      dataType: 'string',
                      props: {
                        hidden: true,
                      },
                    },
                  ],
                },
                {
                  type: 'column',
                  props: { styles: { maxWidth: '150px' } },
                  fields: [
                    {
                      type: 'custom',
                      name: 'rating',
                      label: walkersT('form.fields.rating'),
                      component: () => WalkerRatingCard,
                      props: {},
                    },
                  ],
                },
              ],
            },
            /** Surname, Birthday */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'surname',
                      label: walkersT('form.fields.surname.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: walkersT(
                          'form.fields.surname.placeholder',
                        ),
                        readOnly: true,
                      },
                    },
                  ],
                },
                {
                  type: 'column',
                  props: { styles: { maxWidth: '150px' } },
                  fields: [
                    {
                      type: 'datetime',
                      name: 'birthdate',
                      label: t('form.fields.birthdate.title'),
                      dataType: 'date',
                      props: {
                        type: 'date',
                        format: 'DD.MM.YYYY',
                        placeholder: t('form.fields.birthdate.placeholder'),
                        // eslint-disable-next-line max-len
                        readOnlyFormatter: (val) => (val
                          ? new Date(val as string)
                            .toLocaleDateString()
                            .replaceAll('/', '.')
                          : ''),
                        readOnly: true,
                        changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.birthdate),
                      },
                    },
                  ],
                },
              ],
            },
            /** City, Status */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'select',
                      name: 'city',
                      options: [],
                      label: t('form.fields.city.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: t('form.fields.city.placeholder'),
                        valueKey: 'id',
                        labelKey: 'name',
                        remote: true,
                        remoteMethod: searchCities,
                        readOnly: true,
                        readOnlyFormatter: (val) => val?.name,
                      },
                    },
                  ],
                },
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'select',
                      name: 'status',
                      options: [],
                      label: walkersT('form.fields.status'),
                      dataType: 'string',
                      props: {
                        placeholder: t('form.fields.status'),
                        readOnly: true,
                        readOnlyFormatter: (val) => val?.label,
                        changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.status),
                      },
                    },
                  ],
                },
              ],
            },
            /** Email, Phone */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: { styles: { maxWidth: '190px' } },
                  fields: [
                    {
                      type: 'input',
                      name: 'phone',
                      label: walkersT('form.fields.phone.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: walkersT('form.fields.phone.placeholder'),
                        readOnly: true,
                        mask: {
                          mask: '+7 (###) ###-##-##',
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'email',
                      label: walkersT('form.fields.email.title'),
                      dataType: 'string',
                      props: {
                        placeholder: walkersT('form.fields.email.placeholder'),
                        readOnly: true,
                        changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.email),
                      },
                    },
                  ],
                },
              ],
            },
            /** Telegram, Vkontakte */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'telegram',
                      label: walkersT('form.fields.telegram.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: walkersT(
                          'form.fields.telegram.placeholder',
                        ),
                        readOnly: true,
                      },
                    },
                  ],
                },
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'vk',
                      label: walkersT('form.fields.vk.title'),
                      dataType: 'string',
                      props: {
                        placeholder: walkersT('form.fields.vk.placeholder'),
                        readOnly: true,
                        changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.vk),
                      },
                    },
                  ],
                },
              ],
            },
            /** Offers */
            {
              type: 'column',
              title: t('form.fields.offers'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'input',
                  name: 'offerCategories',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val.map((item: ValueLabel) => item.label).join(', ') || '–',
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.offerCategories),
                  },
                },
              ],
            },
            /** Working schedule */
            {
              type: 'column',
              title: t('form.fields.working_schedule'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'workingSchedule',
                  component: () => WalkerWorkingSchedule,
                  props: {},
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
          },
          layout: [
            /** Photo */
            {
              type: 'column',
              title: t('form.fields.photo'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'dropzone',
                  name: 'photo' as 'photoId',
                  props: {
                    removable: true,
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    inputAtts: {
                      accept: 'image/png, image/jpeg, image/webp',
                    },
                    readOnly: true,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.photo),
                  },
                },
              ],
            },
            /** About */
            {
              type: 'column',
              title: t('form.fields.about'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'textarea',
                  name: 'motivation',
                  label: t('form.fields.motivation.title'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.motivation.placeholder'),
                    rows: 3,
                    readOnly: true,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.motivation),
                  },
                },
                {
                  type: 'textarea',
                  name: 'experience',
                  label: t('form.fields.experience.title'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.experience.placeholder'),
                    rows: 3,
                    readOnly: true,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.experience),
                  },
                },
                {
                  type: 'textarea',
                  name: 'pets',
                  label: t('form.fields.pets_walker.title'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.pets_walker.placeholder'),
                    rows: 3,
                    readOnly: true,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.pets),
                  },
                },

                {
                  type: 'textarea',
                  name: 'interests',
                  label: t('form.fields.interests.title'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.interests.placeholder'),
                    rows: 3,
                    readOnly: true,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.interests),
                  },
                },

                {
                  type: 'input',
                  name: 'video',
                  label: t('form.fields.video_link.title'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && !!state.profileDataOnModeration.video),
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
