import { filtersFormController } from 'magner';
import { OrderFilter } from 'features/orders/types';
import { baseT } from 'configs/translation/base';
import { t } from 'features/orders/ru';
import {
  filterSearchDistricts,
  searchWalkingTypesForCity,
  searchWalkingOptionsForCity,
  searchBreed,
  searchCities,
  searchSource,
  searchStatuses,
} from '~/utils/searchInit';

const orderFilters = filtersFormController<OrderFilter>({
  saveToLocalStorage: true,
  fieldsShowAmount: 5,
  actions: [
    {
      type: 'link',
      to: { name: 'order-edit', params: { id: 'new' } },
      props: {
        type: 'primary',
        class: 'blue-btn',
        text: baseT('form.create_new_button'),
      },
    },
  ],
  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },

  sort: {},

  filtersData: {
    id: '',
    externalId: '',
    statuses: [] as string[],
    paymentStatuses: '',
    startTime: '',
    asap: '',
    walkDate: null,
    city: '',
    districts: [] as string[],
    userName: '',
    userPhone: '',
    walkingType: '',
    walkingTypePriceTo: '',
    walkingTypePriceFrom: '',
    walkingOptions: [] as string[],
    priceFrom: '',
    priceTo: '',
    petBreeds: [] as string[],
    petAgeFrom: '',
    petAgeTo: '',
    weightFrom: '',
    weightTo: '',
    walkerPhone: '',
    walkerName: '',
    ratingFrom: '',
    ratingTo: '',
    source: '',
  },

  layout: [
    {
      type: 'input',
      name: 'id',
      props: {
        placeholder: t('filters.id'),
        inputDelay: 250,
      },
    },
    {
      type: 'input',
      name: 'externalId',
      props: {
        placeholder: t('filters.externalId'),
        inputDelay: 250,
      },
    },
    {
      type: 'datetime',
      name: 'walkDate',
      dataType: 'date',
      props: {
        type: 'datetimerange',
        placeholder: t('filters.walkDate'),
        startPlaceholder: 'От',
        endPlaceholder: 'До',
        format: 'YYYY-MM-DD HH:mm',
        rangeSeparator: '–',
      },
    },
    {
      type: 'select',
      name: 'city',
      options: [],
      props: {
        placeholder: t('filters.city'),
        valueKey: 'id',
        labelKey: 'name',
        remote: true,
        remoteMethod: searchCities,
        collapseTags: true,
      },
      changeAction: ({ form }) => {
        form.districts = [];
        form.walkingType = '';
        form.walkingOptions = [];
      },
    },
    {
      type: 'select',
      name: 'statuses',
      options: [],
      props: {
        placeholder: t('filters.orderStatus'),
        multiple: true,
        valueKey: 'value',
        labelKey: 'label',
        remote: true,
        remoteMethod: searchStatuses,
        collapseTags: true,
      },
    },
    {
      type: 'select',
      name: 'paymentStatuses',
      options: [
        {
          label: 'Оплачено',
          value: 1,
        },
        {
          label: 'Нет оплаты',
          value: 2,
        },
      ],
      props: {
        defaultFirstOption: false,
        placeholder: t('filters.paymentStatus'),
      },
    },
    {
      type: 'select',
      name: 'asap',
      options: [
        {
          label: 'Да',
          value: 1,
        },
        {
          label: 'Нет',
          value: 2,
        },
      ],
      props: {
        defaultFirstOption: false,
        placeholder: t('filters.asap'),
      },
    },
    {
      type: 'select',
      name: 'districts',
      options: [],
      props: {
        placeholder: t('filters.districts'),
        valueKey: 'id',
        labelKey: 'slug',
        loadRemoteMethodOnFocus: true,
        remote: true,
        remoteMethod: filterSearchDistricts,
        multiple: true,
        collapseTags: true,
        filterable: true,
      },
    },
    {
      type: 'input',
      name: 'userName',
      props: {
        placeholder: t('filters.userName'),
        inputDelay: 250,
      },
    },
    {
      type: 'input',
      name: 'userPhone',
      props: {
        placeholder: t('filters.userPhone'),
        inputDelay: 250,
        mask: {
          mask: '+7T#########',
          tokens: { T: { pattern: '[0|1|2|3|4|5|6|7|9]' } },
        },
      },
    },
    {
      type: 'select',
      name: 'walkingType',
      options: [],
      props: {
        placeholder: t('filters.walkingTypes'),
        valueKey: 'id',
        labelKey: 'title',
        remote: true,
        remoteMethod: searchWalkingTypesForCity,
        loadRemoteMethodOnFocus: true,
      },
      changeAction: ({ form }) => {
        form.walkingOptions = [];
      },
    },
    {
      type: 'input',
      name: 'walkingTypePriceFrom',
      props: {
        placeholder: t('filters.walkingTypePriceFrom'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'input',
      name: 'walkingTypePriceTo',
      props: {
        placeholder: t('filters.walkingTypePriceTo'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'select',
      name: 'walkingOptions',
      options: [],
      props: {
        placeholder: t('filters.walkingOptions'),
        multiple: true,
        valueKey: 'id',
        labelKey: 'title',
        remote: true,
        remoteMethod: searchWalkingOptionsForCity,
        loadRemoteMethodOnFocus: true,
      },
    },
    {
      type: 'input',
      name: 'priceFrom',
      props: {
        placeholder: t('filters.priceFrom'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'input',
      name: 'priceTo',
      props: {
        placeholder: t('filters.priceTo'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'select',
      name: 'petBreeds',
      options: [],
      props: {
        placeholder: t('filters.petBreeds'),
        valueKey: 'id',
        labelKey: 'title',
        remote: true,
        remoteMethod: searchBreed,
        filterable: true,
        multiple: true,
        collapseTags: true,
      },
    },
    {
      type: 'input',
      name: 'petAgeFrom',
      props: {
        placeholder: t('filters.petAgeFrom'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'input',
      name: 'petAgeTo',
      props: {
        placeholder: t('filters.petAgeTo'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'input',
      name: 'weightFrom',
      props: {
        placeholder: t('filters.weightFrom'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'input',
      name: 'weightTo',
      props: {
        placeholder: t('filters.weightTo'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'input',
      name: 'walkerPhone',
      props: {
        placeholder: t('filters.walkerPhone'),
        inputDelay: 250,
        mask: {
          mask: '+7T#########',
          tokens: { T: { pattern: '[0|1|2|3|4|5|6|7|9]' } },
        },
      },
    },
    {
      type: 'input',
      name: 'walkerName',
      props: {
        placeholder: t('filters.walkerName'),
        inputDelay: 250,
      },
    },
    {
      type: 'input',
      name: 'ratingFrom',
      props: {
        placeholder: t('filters.ratingFrom'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'input',
      name: 'ratingTo',
      props: {
        placeholder: t('filters.ratingTo'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'select',
      name: 'source',
      options: [],
      props: {
        placeholder: t('filters.source'),
        valueKey: 'value',
        labelKey: 'label',
        multiple: true,
        remote: true,
        remoteMethod: searchSource,
      },
    },
  ],
});

export default orderFilters;
